const allowCors = fn => async (req, res) => {
    res.setHeader('Access-Control-Allow-Credentials', true)
    res.setHeader('Access-Control-Allow-Origin', '*')
    // another option
    res.setHeader('Access-Control-Allow-Origin', req.headers.origin);
    // res.setHeader('Access-Control-Allow-Methods', 'GET,OPTIONS')
    res.setHeader(
        'Access-Control-Allow-Headers',
        'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version'
    )
    if (req.method === 'OPTIONS') {
        res.status(200).end()
        return
    }
    return await fn(req, res)
}

const handler = (req, res) => {
    const d = new Date()
    res.end(d.toString())
}

var accessToken =
    "56fd0da9d0b1507d4fdeab8f14acefb2eadf43af97ca44cd63f0868645f231fd&per_page=3";

const url =
    "https://api.dribbble.com/v2/user/shots?access_token=" + accessToken;

fetch(url)
    .then(res => {
        res.json().then(function (data) {

            let str = data.reduce(function (str, element) {
                let val = element;

                return str.concat(`<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><a target="_blank" rel="noopener, noreferrer" href="${val.html_url}" title="${val.title}"><div class="hover"><img src="${val.images.hidpi}" alt="${val.title}"/></div></a></div>`)
            }, '');

            document.querySelector('#dribbble').innerHTML = str;
            console.log('ok')
        });
    })
    .then(out => { })
    .catch(err => {
        throw err;
    });
